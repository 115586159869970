import { __awaiter, __generator } from "tslib";
import { defineComponent, ref, onMounted, computed } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { notification, screenWidth } from "@/core/helpers/custom";
import { getAddress, deleteAddress, multipleDeleteAddress, } from "@/api/AddressApi";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { debounce } from "lodash";
import BaseTable from "@/components/BaseTable.vue";
import { useI18n } from "vue-i18n/index";
export default defineComponent({
    name: "address-index",
    components: {
        BaseTable: BaseTable,
    },
    setup: function () {
        var _this = this;
        var data = ref([]);
        var query = ref({
            page: 1,
            keyword: "",
            request_limit: "No",
        });
        var tableLoading = ref(false);
        var router = useRouter();
        var selected = ref([]);
        var currentScreenWidth = ref(0);
        var t = useI18n().t;
        var headerConfig = computed(function () {
            if (currentScreenWidth.value > 425) {
                return [
                    {
                        key: "checkbox",
                        sortable: false,
                    },
                    {
                        name: "Address Name",
                        key: "name",
                        sortable: false,
                    },
                    // {
                    //   name: "Full Name",
                    //   key: "firstname",
                    //   sortable: true,
                    // },
                    {
                        name: "Address",
                        key: "address",
                        sortable: false,
                    },
                    {
                        name: "Status",
                        key: "is_default",
                        sortable: false,
                    },
                    {
                        name: "Actions",
                        key: "actions",
                    },
                ];
            }
            else {
                return [
                    {
                        key: "checkbox",
                        sortable: false,
                    },
                    {
                        name: "Address Name",
                        key: "name",
                        sortable: false,
                    },
                    {
                        name: "Actions",
                        key: "actions",
                    },
                ];
            }
        });
        var searchByKeyword = debounce(function () {
            getData();
        }, 500);
        var resetSearch = function () {
            query.value.keyword = "";
            getData();
        };
        var multipleDelete = function () {
            Swal.fire({
                title: t("Are you sure you want to remove address?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                var res;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 2];
                            return [4 /*yield*/, multipleDeleteAddress({
                                    ids: selected.value,
                                })];
                        case 1:
                            res = _a.sent();
                            notification({
                                type: "success",
                                message: res.data.message,
                            });
                            selected.value = [];
                            _a.label = 2;
                        case 2:
                            getData();
                            return [2 /*return*/];
                    }
                });
            }); });
        };
        var removeById = function (id) {
            Swal.fire({
                title: t("Are you sure you want to remove address?"),
                showCancelButton: true,
                confirmButtonText: t("Yes"),
                cancelButtonText: t("Cancel"),
                confirmButtonColor: "#00AF27",
            }).then(function (result) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 2];
                            return [4 /*yield*/, deleteAddress(id)
                                    .then(function (res) {
                                    notification({
                                        type: "success",
                                        message: res.data.message,
                                    });
                                })
                                    .catch(function () {
                                    "_";
                                })];
                        case 1:
                            _a.sent();
                            getData();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
        };
        var getData = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        tableLoading.value = true;
                        return [4 /*yield*/, getAddress(query.value).then(function (res) {
                                data.value = ref(res.data.data).value;
                                pagination.value = {
                                    page: res.data.meta.current_page,
                                    total: res.data.meta.total,
                                    rowsPerPage: res.data.meta.per_page,
                                    totalPage: res.data.meta.last_page,
                                };
                            })];
                    case 1:
                        _a.sent();
                        tableLoading.value = false;
                        return [2 /*return*/];
                }
            });
        }); };
        var changePage = function (page) {
            query.value.page = page;
            getData();
        };
        var resizeScreen = debounce(function () {
            currentScreenWidth.value = screenWidth();
        }, 100);
        onMounted(function () {
            setCurrentPageBreadcrumbs("Address", ["Account"]);
            getData();
            resizeScreen();
            window.addEventListener("resize", resizeScreen);
        });
        var pagination = ref({});
        return {
            data: data,
            headerConfig: headerConfig,
            pagination: pagination,
            changePage: changePage,
            tableLoading: tableLoading,
            router: router,
            removeById: removeById,
            selected: selected,
            multipleDelete: multipleDelete,
            searchByKeyword: searchByKeyword,
            query: query,
            resetSearch: resetSearch,
            currentScreenWidth: currentScreenWidth,
        };
    },
});
